<template>
  <div class="play_box">
    <!-- <common-nav /> -->
    <div class="wrap play_content">
      <div class="left_box">
        <div v-for="(item, index) in leftNav" :key="index" class="left_nav_box" :class="{selected: item.url === currentNav}" @click="toContent(item)">{{item.name}}</div>
      </div>
      <div class="right_box">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
// import commonNav from '@/component/commonNav'
export default {
  name: 'play',
  components: {
    // commonNav
  },
  data () {
    return {
      leftNav: [
        {
          name: '留声机',
          url: 'recorder'
        }, {
          name: '速记练习',
          url: 'shortMemory'
        }, {
          name: 'todoList',
          url: 'todoList'
        }, {
          name: 'lookForCM',
          url: 'lookForCM'
        }
      ],
      currentNav: 'shortMemory'
    }
  },
  mounted () {
    // console.log('-----', this.$route.path)
    this.currentNav = ''
    this.leftNav.forEach(item => {
      console.log('-----item', this.$route.path.indexOf(item.url))
      if (this.$route.path.indexOf(item.url) !== -1) {
        this.currentNav = item.url
      } else {
        if (item.url === 'shortMemory') {
          this.toContent(item)
        }
      }
    })
  },
  methods: {
    toContent (item) {
      console.log('点击了留声机')
      this.currentNav = item.url
      this.$router.push({
        path: '/play/' + item.url
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>